/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
.container-fluid {
  width: 100% !important;
  padding-right: 0px  !important;
  padding-left: 0px !important;
  padding: 0 !important;
}
/* .main{
  width:100%;
} */

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}

/* .ml-auto{
  margin-left: auto !important;
  background-color: black;
} */
.navbar {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
/* #subject {
  background-color: #FF00FF;
} */

/* .profile-div{
  transform: scale(0.5, 0.5);
} */